@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  font-family: 'Roboto', sans-serif;
  font-size: medium;
  margin: 0;
  margin: auto;
  padding: 0;
  text-decoration: none;
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

/*Special container div created for the build page.*/
.BC-div {
  min-height: calc(100svh - 6.3rem);
  max-width: 800px;
  overflow: hidden;
  margin-bottom: -0.25rem;
}

/* Default container div. */
.Content-div {
  min-height: calc(100svh - 6.3rem);
  max-width: 800px;
  overflow: hidden;
}

/* Special container div for the home page. */
.Introduction-div {
  min-height: calc(100svh - 6.3rem);
  max-width: 800px;
  background-image: url('https://mainbuilds.com/media/background/code.png');
  background-size: 100% 100%;
}

.Tooltip-div {
  background-image: url('https://mainbuilds.com/media/background/black-slate.png');
  border-radius: 0.5rem;
  border: 0.25rem solid var(--randomColorOne);
  font-size: small;
  height: 21rem;
  left: 50%;
  line-height: 1.25;
  padding: 0.25rem;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  width: 15.75rem;
}

.Skills-img,
.Tooltip-img {
  display: inline-block;
  border-radius: 0.2rem;
  margin: 0.2rem;
  border: 0.1rem solid #9f9b86;
  width: 49.5px;
}

.Tooltip-p {
  font-size: inherit;
  background-color: transparent;
}

body {
  background-image: linear-gradient(black, var(--randomColorOne));
}