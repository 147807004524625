.Felms-fieldset,
.Llothis-fieldset,
.Instructions-fieldset {
    background-image: url('https://mainbuilds.com/media/background/chalkboard.png');
    border-radius: 0.5rem;
    border: 0.25rem solid goldenrod;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    max-width: 75%;
    min-width: 75;
    padding: 0.25rem;
    width: 75%;
}

.Felms-legend,
.Llothis-legend {
    background-color: transparent;
}

#Felms-table,
#Llothis-table {
    transition: 0.3s background;
    width: 100%;
}

#Felms-table.warning,
#Llothis-table.warning {
    background: #ab2232;
}

.Felms-tbody,
.Llothis-tbody {
    text-align: center;
}

.Timer-td,
.Buttons-td {
    column-span: all;
}

#FelmsPercentBar-div,
#LlothisPercentBar-div {
    background: #ab2232;
    font-size: 25px;
    height: 2rem;
    text-align: right;
    transition: width 0.9s;
    width: 0;
    z-index: 0;
}

#FelmsText-div,
#LlothisText-div {
    font-size: 25px;
}

#FelmsTimer-div,
#LlothisTimer-div {
    font-size: 25px;
}

.Felms-h1,
.Llothis-h1 {
    animation-direction: alternate;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-name: title;
    background-color: transparent;
    font-size: 25px;
    text-align: center;
}

#FelmsStart-button,
#FelmsReset-button,
#LlothisStart-button,
#LlothisReset-button {
    animation-direction: alternate;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-name: title;
    font-size: 25px;
    height: 3.5rem;
    width: 7rem;
    font-weight: bold;
    background: linear-gradient(to bottom, antiquewhite 0%, cadetblue 100%);
    border: 1px solid #e2efd3;
    border-radius: 25px;
}

.Instructions-h1 {
    animation-direction: alternate;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-name: title;
    background-color: transparent;
    font-size: 25px;
    text-align: center;
}

.Instructions-div {
    padding: 0.5rem;
    font-size: inherit;
    line-height: 1.1;
    background-color: transparent;
}

.Instructions-a {
    font-size: inherit;
    border: 1px dashed #00d9ff;
    padding: 0.25rem;
}

.Instructions-p,
.Credit-p {
    background-color: inherit;
    font-size: large;
}

.Instructions-legend {
    background-color: transparent;
}

.Instructions-span {
    background-color: transparent;
    font-size: 14px;
    position: relative;
}

.Credit-span {
    background-color: transparent;
    color: gold;
    font-size: 14px;
    position: relative;
}

.Credit-button {
    animation-direction: alternate;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-name: title;
    height: 1.75rem;
    width: 3.5rem;
    background: linear-gradient(to bottom, antiquewhite 0%, cadetblue 100%);
    border: 1px solid #e2efd3;
    border-radius: 25px;
}