.PvP-div,
.PvE-div {
    text-align: center;
}

.PvP-h1,
.PvE-h1 {
    animation-direction: alternate;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-name: title;
    background-color: transparent;
    font-size: 25px;
    text-align: center;
}

.Discord-a {
    color: inherit;
}

.Discord-ul {
    border: 1px dashed #00d9ff;
    padding: 0.25rem;
    display: table;
    background-color: transparent;
}

.Discord-span:hover {
    transition: all 0.2s ease-in;
    color: #00d9ff;
}

.Discord-span {
    position: relative;
    top: -2px;
    background-color: transparent;
    font-size: large;
}

.EPA-li {
    list-style-position: inside;
    list-style-image: url('https://mainbuilds.com/media/discord/eso-pvp-academy-small.png');
    background-color: transparent
}

.HC-li {
    list-style-position: inside;
    list-style-image: url('https://mainbuilds.com/media/discord/hoarcrux-yt-pvp-small.png');
    background-color: transparent
}

.Unify-li {
    list-style-position: inside;
    list-style-image: url('https://mainbuilds.com/media/discord/unify-small.png');
    background-color: transparent;
}

.TD-li {
    list-style-position: inside;
    list-style-image: url('https://mainbuilds.com/media/discord/total-darkness-small.png');
    background-color: transparent;
}

.PvP-fieldset,
.PvE-fieldset {
    background-image: url('https://mainbuilds.com/media/background/chalkboard.png');
    border-radius: 0.5rem;
    border: 0.25rem solid goldenrod;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    max-width: 75%;
    min-width: 75;
    padding: 0.5rem;
    width: 75%;
}

.PvP-legend,
.PvE-legend {
    background-color: inherit;
    background-color: transparent;
}