.Disclaimer-div {
  padding: 0.5rem;
  margin-top: -1rem;
  line-height: 1.5;
  background-color: transparent;
}

.Disclaimer-h1 {
  animation-direction: alternate;
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
  animation-name: title;
  background-color: transparent;
  font-size: 25px;
  text-align: center;
}

.Disclaimer-a {
  font-size: inherit;
  border: 1px dashed #00d9ff;
  padding: 0.25rem;
}

.Disclaimer-p {
  background-color: inherit;
  font-size: large;
}

.Disclaimer-legend {
  background-color: transparent;
}

.Disclaimer-fieldset {
  background-image: url('https://mainbuilds.com/media/background/chalkboard.png');
  border-radius: 0.5rem;
  border: 0.25rem solid goldenrod;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  max-width: 75%;
  min-width: 75;
  padding: 0.5rem;
  width: 75%;
}