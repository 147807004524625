.Submit-h1 {
    animation-direction: alternate;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-name: title;
    background-color: transparent;
    font-size: 25px;
    text-align: center;
}

.Submit-div {
    padding: 0.5rem;
    font-size: inherit;
    line-height: 1.5;
    background-color: transparent;
}

.Submit-a {
    font-size: inherit;
    border: 1px dashed #00d9ff;
    padding: 0.25rem;
}

.Submit-p {
    background-color: inherit;
    font-size: large;
}

.Submit-legend {
    background-color: transparent;
}

.Submit-fieldset {
    background-image: url('https://mainbuilds.com/media/background/chalkboard.png');
    border-radius: 0.5rem;
    border: 0.25rem solid goldenrod;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    max-width: 75%;
    min-width: 75;
    padding: 0.5rem;
    width: 75%;
}

.Number-span {
    background-color: transparent;
    font-size: inherit;
    position: relative;
}